import Main from '../Main/Main'

function App() {
  return (
    <div style={{'boxSizing': 'border-box', width: '100vw'}}>
      <Main />
    </div>
  )
}

export default App;
